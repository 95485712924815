import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import AlertContext from "../../../../context/AlertContext";
import { SelectorGenerico } from "../../../Selector/";
import { Alert } from "../../../Alerts";

export default function ModalMenuOption({
    action,
    actionText,
    indexItem,
    handleAction,
}) {
    const [open, setOpen] = useState(false);

    const { url, headers, workstationActive, user } = useContext(UserContext);
    const { data, setData, select, setSelect } = useContext(ModalContext);
    const { setResponseText, setStatusCode } = useContext(AlertContext);
    const cancelButtonRef = useRef(null);
    const [error, setError] = useState("");
    const [fechaEntrega, setFechaEntrega] = useState(
        Moment(data.Items[indexItem].FechaEntrega).format("YYYY-MM-DD")
    );
    const [cantidadArticulo, setCantidadArticulo] = useState(
        data.Items[indexItem].ItemOperacion.Cantidad
    );

    const handleChange = (e) => {
        e.preventDefault();
        switch (action) {
            case "del_article":
                deleteArticle();
                break;
            case "change_amount_article":
                if (
                    cantidadArticulo === 0 ||
                    cantidadArticulo === "" ||
                    cantidadArticulo === undefined
                ) {
                    setError("El valor no puede ser un valor nulo");
                } else {
                    peticion(cantidadArticulo);
                }
                break;
            case "change_article_delivery_date":
                if (fechaEntrega === "" || fechaEntrega === undefined) {
                    setError("La fecha ingresada no es válida");
                } else {
                    peticion(
                        Moment(fechaEntrega).format("YYYY-MM-DDThh:mm:ss")
                    );
                }
                break;

            case "change_article_active":
            case "change_article_supplier_indicated": 
            case "change_article_delivery_deposit":
                if (select === undefined || select === null) {
                    setError("Debe seleccionar una opción para aceptar");
                } else {
                    peticion(select);
                }
                break;
                
            default:
                peticion();
                break;
        }
    };

    const peticion = async (param) => {
        console.log(param);
        await axios
            .post(
                url + "ordenpedidointerna/" + action + "/" + indexItem + `${param !== undefined ? "/" + param : ''}`, {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                ordenpedidointerna: data,
            })
            .then(function (response) {
                console.log(response.data);
                setData(response.data);
                handleAction();
                setOpen(false);
                setSelect([]);
            })
            .catch(function (error) {
                console.log(error.response);
                setOpen(false);
                if (error.response != undefined) {
                    if (error.response.status === 404) {
                        setResponseText(
                            "Error de petición. Consulte con administración"
                        );
                    } else {
                        setResponseText(error.response.data.Motivo);
                    }
                    setStatusCode(error.response.status);
                } else {
                    setResponseText(
                        "Hubo un error crítico. Consulte con administración"
                    );
                }
            });
    };

    const deleteArticle = async () => {
        await axios
            .post(url + "ordenpedidointerna/" + action + "/" + indexItem, {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                ordenpedidointerna: data,
            })
            .then(function (response) {
                setData(response.data);
                handleAction();
                setOpen(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setOpen(false);
            });
    };

    const handleFechaArticulo = ({ target }) => {
        const { value } = target;
        // console.log(value);
        setFechaEntrega(value);
    };

    const handleCantidadArticulo = ({ target }) => {
        const { value } = target;
        setCantidadArticulo(value);
    };

    const cancelButton = () => {
        setOpen(false);
        handleAction();
    };

    return (
        <>
            <button
                className="w-full block text-left px-4 py-1 text-sm text-gray-700 hover:bg-gray-200"
                onClick={() => setOpen(!open)}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-20 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-auto sm:p-6">
                                    <form onSubmit={handleChange}>
                                        {
                                            // del_article
                                            // change_amount_article
                                            // change_article_delivery_date
                                            // change_article_delivery_date
                                            // change_article_center
                                            // change_article_supplier
                                            // change_article_active
                                            {
                                                del_article: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro
                                                                    que quiere
                                                                    eliminar el
                                                                    artículo "
                                                                    {
                                                                        data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ItemOperacion
                                                                            .Item
                                                                            .Nombre
                                                                    }
                                                                    " (
                                                                    {
                                                                        data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ItemOperacion
                                                                            .Item
                                                                            .Codigo
                                                                    }
                                                                    )
                                                                    <br />
                                                                    Esta acción
                                                                    no se puede
                                                                    deshacer.
                                                                    Deberá
                                                                    cargar
                                                                    nuevamente
                                                                    el artículo.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                                change_amount_article: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="">
                                                                <p className="text-sm text-gray-500">
                                                                    Debe
                                                                    escribir un
                                                                    numero
                                                                    válido.
                                                                </p>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    className="rounded-md text-xs"
                                                                    type="number"
                                                                    name="cantidad"
                                                                    value={
                                                                        cantidadArticulo
                                                                    }
                                                                    onChange={
                                                                        handleCantidadArticulo
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                                change_article_supplier_indicated:
                                                    (
                                                        <>
                                                            <SelectorGenerico
                                                                ruta={
                                                                    "proveedor"
                                                                }
                                                                view={"single"}
                                                                title={
                                                                    "Seleccione un proveedor"
                                                                }
                                                                id={
                                                                    data.Items[
                                                                        indexItem
                                                                    ]
                                                                        .ProveedorIndicado !==
                                                                        null &&
                                                                    data.Items[
                                                                        indexItem
                                                                    ]
                                                                        .ProveedorIndicado &&
                                                                    data.Items[
                                                                        indexItem
                                                                    ]
                                                                        .ProveedorIndicado
                                                                        .ProveedorKey
                                                                }
                                                            />
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </>
                                                    ),
                                                change_article_delivery_date: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="">
                                                                <p className="text-sm text-gray-500">
                                                                    Debe
                                                                    escribir una
                                                                    fecha
                                                                    válida.
                                                                </p>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    className="rounded-md text-xs"
                                                                    type="date"
                                                                    value={
                                                                        fechaEntrega
                                                                    }
                                                                    onChange={
                                                                        handleFechaArticulo
                                                                    }
                                                                    name="fechaDelivery"
                                                                />
                                                            </div>
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                                change_article_delivery_deposit:
                                                    (
                                                        <>
                                                            <SelectorGenerico
                                                                ruta={
                                                                    "deposito"
                                                                }
                                                                view={"single"}
                                                                title={
                                                                    "Seleccione un deposito"
                                                                }
                                                                id={
                                                                    data.Items[indexItem]
                                                                        .ItemOperacion
                                                                        .DepositoKey !==
                                                                        null &&
                                                                    data.Items[indexItem]
                                                                        .ItemOperacion.DepositoKey &&
                                                                    data.Items[
                                                                        indexItem
                                                                    ]
                                                                        .ItemOperacion
                                                                        .DepositoKey
                                                                }
                                                            />
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </>
                                                    ),
                                                change_article_active: (
                                                    <>
                                                        <SelectorGenerico
                                                            ruta={"bienuso"}
                                                            view={"single"}
                                                            title={
                                                                "Seleccione un bien de uso"
                                                            }
                                                            id={
                                                                data.Items[
                                                                    indexItem
                                                                ].ItemOperacion
                                                                    .BienUso !==
                                                                    null &&
                                                                data.Items[
                                                                    indexItem
                                                                ].ItemOperacion
                                                                    .BienUso &&
                                                                data.Items[
                                                                    indexItem
                                                                ].ItemOperacion
                                                                    .BienUso
                                                                    .BienUsoKey
                                                            }
                                                        />
                                                        {error && (
                                                            <p className="text-xs text-red-500 font-semibold">
                                                                {error}
                                                            </p>
                                                        )}
                                                    </>
                                                ),
                                                remove_article_supplier_indicated:
                                                    (
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6 text-red-600"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                                <Dialog.Title
                                                                    as="h3"
                                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                                >
                                                                    {actionText}
                                                                </Dialog.Title>
                                                                <div className="mt-2">
                                                                    <p className="text-sm text-gray-500">
                                                                        ¿Está
                                                                        seguro
                                                                        que
                                                                        quiere
                                                                        quitar
                                                                        el
                                                                        proveedor
                                                                        "
                                                                        {data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ProveedorIndicado &&
                                                                            data
                                                                                .Items[
                                                                                indexItem
                                                                            ]
                                                                                .ProveedorIndicado
                                                                                .RazonSocial}
                                                                        <br />
                                                                        Esta
                                                                        acción
                                                                        no se
                                                                        puede
                                                                        deshacer.
                                                                        Deberá
                                                                        cargar
                                                                        nuevamente
                                                                        el
                                                                        proveedor.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ),
                                                remove_article_active: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro
                                                                    que quiere
                                                                    quitar el
                                                                    bien de uso
                                                                    "
                                                                    {data.Items[
                                                                        indexItem
                                                                    ]
                                                                        .ItemOperacion
                                                                        .BienUso &&
                                                                        data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ItemOperacion
                                                                            .BienUso
                                                                            .Nombre}
                                                                    "
                                                                    <br />
                                                                    Esta acción
                                                                    no se puede
                                                                    deshacer.
                                                                    Deberá
                                                                    cargar
                                                                    nuevamente
                                                                    el
                                                                    proveedor.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            }[action]
                                        }
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="submit"
                                                className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm ${
                                                    action === "del_article"
                                                        ? " bg-red-600 hover:bg-red-700"
                                                        : "bg-blue-600 hover:bg-blue-800"
                                                }`}
                                            >
                                                {action === "del_article"
                                                    ? "Eliminar"
                                                    : "Aceptar"}
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={cancelButton}
                                                ref={cancelButtonRef}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
