import { Fragment, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import UserContext from "../../../../context/UserContext";
import AlertContext from "../../../../context/AlertContext";
import WaitMessageContext from "../../../../context/WaitMessageContext";

export const GeneraItems = ({
    action,
    actionText,
    item,
    refresh,
    id,
    requestEndpoint = "",
    originEndpoint = "",
    originOperation = "",
    requestOperation,
    handlePopover,
}) => {
    const cancelButtonRef = useRef(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [configuraciones, setConfiguraciones] = useState([])
    const [requestData, setRequestData] = useState([]);
    const [newValue, setNewValue] = useState(null);
    const { setStatusCode, setResponseText } = useContext(AlertContext);
    const { setWait, setWaitMessage } = useContext(WaitMessageContext);
    const { url, headers, user, workstationActive } = useContext(UserContext);

    // console.log(url +  + "/readonly/" + id);
    // console.log(originEndpoint);
    
    const initialSearchOperation = async() => {
        setWait(true);
        setWaitMessage("Espere . . .");
        await axios.post(url + originEndpoint, {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
            })
            .then(function (response) {
                // setWait(false)
                if (response.status === 200) {
                    console.log(response.data);
                    setWait(false)
                    setOpen(true);
                    setData(response.data);
                    setConfiguraciones(response.data.configuraciones);

                }
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.data && "Motivo" in error.response.data) {
                    setResponseText(error.response.data["Motivo"]);
                    setStatusCode(error.response.status);
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                    setWait(false);
                } else {
                    setResponseText("");
                    setTimeout(() => {
                        setWaitMessage(
                            "Hubo un error. Presione el botón para recargar el sitio"
                        );
                    }, 1000);
                }
            });
    }

    const handleItemChange = (index, event) => {
        const value = Number(event.target.value);

        setData((prevData) => ({
            ...prevData,
            itemsoperaciongenera: prevData.itemsoperaciongenera.with(index, {
                ...prevData.itemsoperaciongenera[index],
                A_Cancelar: value,
            }),
        }));
    };

    const handleRedirectToComponent = () => {
        
        const params = {
            url_post: data.url_post,
            generadora: {
                operacionorigen: data.operacionorigen,
                url_post: data.url_post,
                configuraciones: configuraciones,
                itemsoperaciongenera: {
                    ...data.itemsoperaciongenera,
                },
            },
        };
        console.log(params);
        
        // navigate('/venta', { state: params});
    }
    
    return (
        <>
            <button
                className="flex items-center font-medium text-left px-4 py-2 text-sm text-gray-700 hover:underline"
                onClick={initialSearchOperation}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    <div className="w-full mb-5 text-center">
                                        <p className="text-medium font-bold leading-7 text-gray-800 text-left md:text-center">
                                            {/* Generar items de {originOperation} :{" "}
                                            {id} */}
                                            {configuraciones?.descripcion}
                                        </p>
                                    </div>
                                    {/* {(() => {
                                        switch (requestAction) {
                                            case "cambio_estado":
                                                return (
                                                    <div
                                                        className={`${
                                                            loading
                                                                ? "opacity-50"
                                                                : "opacity-100"
                                                        }`}
                                                    >
                                                        <div>
                                                            <label className="text-base font-medium text-gray-900">
                                                                Estados
                                                                disponibles
                                                            </label>
                                                            <p className="text-sm leading-5 text-gray-500">
                                                                Elija el estado
                                                                que quiera
                                                                cambiar la
                                                                operación
                                                            </p>
                                                            <fieldset className="mt-4">
                                                                <legend className="sr-only">
                                                                    State change
                                                                    method
                                                                </legend>
                                                                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                    {requestData.map(
                                                                        (
                                                                            est,
                                                                            estIdx
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    estIdx
                                                                                }
                                                                                className="flex items-center"
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    checked={
                                                                                        newValue ==
                                                                                        est.id
                                                                                    }
                                                                                    value={
                                                                                        est.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setNewValue(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label
                                                                                    htmlFor={
                                                                                        est.id
                                                                                    }
                                                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                                                >
                                                                                    {
                                                                                        est.Nombre
                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                );
                                            case "retorno_estado":
                                                return (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                            <Dialog.ItemOperacion.Item.Nombre
                                                                as="h3"
                                                                className="text-base font-semibold leading-6 text-gray-900"
                                                            >
                                                                Retornar un
                                                                estado anterior
                                                            </Dialog.ItemOperacion.Item.Nombre>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro
                                                                    que quiere
                                                                    volver un
                                                                    estado
                                                                    anterior de
                                                                    la
                                                                    operación?
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()} */}

                                    <div>
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                                    >
                                                        Codigo
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                                    >
                                                        Nombre
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                                    >
                                                        Stock
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                                    >
                                                        Cant.
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        Restante
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        A cancelar
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                                    >
                                                        <span className="sr-only">
                                                            Edit
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {data.itemsoperaciongenera?.map(
                                                    (item, idx) => (
                                                        <tr key={idx}>
                                                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                                                {item.Codigo}
                                                                <dl className="font-normal lg:hidden">
                                                                    <dt className="sr-only">
                                                                        Nombre
                                                                    </dt>
                                                                    <dd className="mt-1 truncate text-gray-700">
                                                                        {
                                                                            item.Nombre
                                                                        }
                                                                    </dd>
                                                                    <dt className="sr-only sm:hidden">
                                                                        Codigo
                                                                    </dt>
                                                                    <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                                        {
                                                                            item.Codigo
                                                                        }
                                                                    </dd>
                                                                </dl>
                                                            </td>
                                                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                                                {item.Nombre}
                                                            </td>
                                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                                {item.Stock}
                                                            </td>
                                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                                {item.Cantidad}
                                                            </td>
                                                            <td className="px-3 py-4 text-sm text-gray-500">
                                                                {item.Restante}
                                                            </td>
                                                            <td className="px-3 py-4 text-sm text-gray-500">
                                                                <input
                                                                    type="number"
                                                                    value={
                                                                        item.A_Cancelar
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        handleItemChange(
                                                                            idx,
                                                                            event
                                                                        )
                                                                    }
                                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </td>
                                                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                                {/* <a
                                                                    href="#"
                                                                    className="text-indigo-600 hover:text-indigo-900"
                                                                >
                                                                    Edit
                                                                    <span className="sr-only">
                                                                        ,{" "}
                                                                        {
                                                                            item.Nombre
                                                                        }
                                                                    </span>
                                                                </a> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div
                                        className={`mt-5 sm:mt-4 sm:flex sm:flex-row-reverse ${
                                            loading
                                                ? "opacity-50"
                                                : "opacity-100"
                                        }`}
                                    >
                                        <button
                                            type="button"
                                            className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm bg-blue-600 hover:bg-blue-800 ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={handleRedirectToComponent}
                                        >
                                            Aceptar
                                        </button>
                                        <button
                                            type="button"
                                            className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm ${
                                                loading
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer"
                                            }`}
                                            onClick={() => setOpen(!open)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
