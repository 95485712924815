import {useState, useContext, useEffect} from 'react'

import ModalContext from "../../../context/ModalContext";
import { TripleMaze } from "react-spinner-animated";

import { Table } from "../../TableComponent";

export const ListItemCliente = ({
    clientes,
    columns,
    nextStep,
    sortConfig,
    setSortConfig,
    busqueda,
    setBusqueda
}) => {
    const [alerta, setAlerta] = useState("");
    const [loading, setLoading] = useState(false);

    const { select } = useContext(ModalContext);

    useEffect(() => {
        if (select.length > 0) {
            setAlerta("");
        }
    }, [select]);

    const handleValidation = () => {
        var validation = false;

        if (select.length > 0) {
            validation = true;
        }

        if (validation) {
            
            nextStep();
        } else {
            setAlerta("Debe seleccionar un cliente para avanzar");
        }
    };

    return (
        <>
            <div>
                {alerta && (
                    <div className="rounded-md bg-yellow-100 p-4 absolute bottom-2 right-96 z-20 ">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-yellow-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-yellow-800">
                                    {alerta}
                                </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        onClick={() => setAlerta("")}
                                        className="inline-flex rounded-md bg-yellow-100 p-1.5 text-yellow-600 hover:bg-yellow-200 focus:outline-none"
                                    >
                                        <span className="sr-only">Close</span>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex justify-between mb-2 mt-3">
                    <div className="relative rounded-md shadow-sm justify-end w-1/2 mx-auto">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            value={busqueda}
                            onChange={(e) => setBusqueda(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Búsqueda inteligente"
                        />
                    </div>
                    <button
                        className={`bg-gray-800 text-white hover:bg-gray-700 px-2 py-1 rounded-md shadow-xl text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={handleValidation}
                    >
                        Siguiente
                    </button>
                </div>

                {loading ? (
                    <TripleMaze
                        centered={true}
                        width={"350px"}
                        height={"350px"}
                        text={"Cargando..."}
                    />
                ) : (
                    <div className="h-80 overflow-y-auto">
                        <Table
                            data={clientes}
                            column={columns}
                            view={"single"}
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            busqueda={busqueda}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
