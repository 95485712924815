//react
import axios from "axios";
import { useState, useEffect } from "react";

//components

import { SelectorArticulo } from "../../../Selector/SelectorByListaPrecio/SelectorArticulo";

//assets
import { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import { ArticulosItem } from "./ArticulosItem";
import { useActions } from "../../../../permissions/useActions";

export const SecondComponent = ({ nextStep, prevStep }) => {
    const { url, workstationActive, user } = useContext(UserContext);
    const { data, setData } = useContext(ModalContext);
    const { isAllowed } = useActions();
    var cantidad = 1;
    const [items, setItems] = useState([]);

    const handleAddArticle = async (e) => {
        await axios.post(
                url + `ordenpedidointerna/add_article/${e}/${cantidad}`,
                {
                    Workstation: workstationActive.WorkstationKey,
                    Usuario: user.UsuarioKey,
                    ordenpedidointerna:data,
                }
            )
            .then(function (response) {
                console.log(response);
                setData(response.data);
                setItems(response.data.Items);
                // console.log(response.data.Items);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    useEffect(() => {
        setItems(data.Items);
    }, [data]);

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-between">
                    <button
                        className={`bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={prevStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.85Z"
                            ></path>
                        </svg>
                    </button>
                    <div className="mx-4 w-full">
                        <SelectorArticulo
                            onAddArticle={handleAddArticle}
                            listaPrecioKey={
                                data.Operacion.ListaPrecios === null
                                    ? 1
                                    : data.Operacion.ListaPrecios
                                          .ListaPreciosKey
                            }
                        />
                    </div>
                    <button
                        className={`ml-15 bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={nextStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="px-4 max-h-80 h-80 sm:px-6 lg:px-8 mt-2">
                <div className="flow-root">
                    <div className="-mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="overflow-auto h-80">
                            <table className="min-w-full border-separate border-spacing-0 ">
                                <thead className="bg-gray-300">
                                    <tr className="border">
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b text-center border-indigo-600 bg-white bg-opacity-75 py-1 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            #
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b text-left border-indigo-600 bg-white bg-opacity-75  px-3 py-1 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            Código
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-indigo-600 bg-white bg-opacity-75  px-3 py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <span className="md:block hidden">
                                                Artículo
                                            </span>
                                            <span className="md:hidden block py-1">
                                                Datos
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b text-right border-indigo-600 bg-white bg-opacity-75  py-1 px-3 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            Cantidad
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b border-indigo-600 bg-white bg-opacity-75  py-1 px-3 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            Bien de uso
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b border-indigo-600 bg-white bg-opacity-75  py-1 px-3 w-0 text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            Proveedor
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b border-indigo-600 bg-white bg-opacity-75  py-1 px-3 w-0 text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            Fecha Entrega
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-indigo-600 bg-white bg-opacity-75  w-0 py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {items.map((item, itemIdx) => (
                                        <ArticulosItem
                                            key={itemIdx}
                                            item={item}
                                            itemIdx={itemIdx}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
