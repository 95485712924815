import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import { SelectorGenerico } from "../../../Selector/";

export default function ModalMenuOption({
    action,
    actionText,
    indexItem,
    handleAction,
}) {
    const [open, setOpen] = useState(false);

    const { url, headers, workstationActive, user } = useContext(UserContext);
    const { data, setData, select, setSelect } = useContext(ModalContext);
    const cancelButtonRef = useRef(null);
    const [error, setError] = useState("");
    const [fechaEntrega, setFechaEntrega] = useState(
        Moment(data.Items[indexItem].FechaEntrega).format("YYYY-MM-DD")
    );
    const [precioArticulo, setPrecioArticulo] = useState(
        parseFloat(
            data.Items[indexItem].ItemOperacion.MontoUnitarioNGMoneda
        ).toFixed(2)
    );
    const [cantidadArticulo, setCantidadArticulo] = useState(
        data.Items[indexItem].ItemOperacion.Cantidad
    );
    const [descuentoArticulo, setDescuentoArticulo] = useState(
        data.Items[indexItem].ItemOperacion.PorcentajeDescuento
    );

    const handleChange = (e) => {
        e.preventDefault();
        switch (action) {
            case "del_article":
                deleteArticle();
                break;
            case "change_amount_article":
                if (
                    cantidadArticulo === 0 ||
                    cantidadArticulo === "" ||
                    cantidadArticulo === undefined
                ) {
                    setError("El valor no puede ser un valor nulo");
                } else {
                    peticion(cantidadArticulo);
                }
                break;
            case "change_article_price":
                if (
                    precioArticulo === 0 ||
                    precioArticulo === "" ||
                    precioArticulo === undefined
                ) {
                    setError("El número ingresado es incorrecto");
                } else {
                    peticion(precioArticulo);
                }
                break;
            case "change_article_discount_percentage":
                if (
                    descuentoArticulo === 0 ||
                    descuentoArticulo === "" ||
                    descuentoArticulo === undefined
                ) {
                    setError("El número ingresado es incorrecto");
                } else if (descuentoArticulo.match(/[^0-9]/)) {
                    setError("Debe ingresar únicamente numeros");
                } else {
                    peticion(descuentoArticulo);
                }
                break;
            case "change_article_delivery_date":
                if (fechaEntrega === "" || fechaEntrega === undefined) {
                    setError("La fecha ingresada no es válida");
                } else {
                    peticion(
                        Moment(fechaEntrega).format("YYYY-MM-DDThh:mm:ss")
                    );
                }
                break;
            case "change_article_delivery_deposit":
                if (select === undefined || select === null) {
                    setError("Debe seleccionar un depósito");
                } else {
                    peticion(select);
                }
                break;
        }
    };

    const peticion = async (param) => {
        await axios
            .post(url + "ordencompracliente/" + action + "/" + indexItem + "/" + param,{
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                ordencompracliente: data,
            })
            .then(function (response) {
                // console.log(response.data);
                setData(response.data);
                handleAction(false);
                setOpen(false);
                setSelect([]);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const deleteArticle = async () => {
        await axios
            .post(url + "ordencompracliente/" + action + "/" + indexItem, {
                // headers: headers,
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
                ordencompracliente: data,
            })
            .then(function (response) {
                setData(response.data);
                handleAction(false);
                setOpen(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleFechaArticulo = ({ target }) => {
        const { value } = target;
        // console.log(value);
        setFechaEntrega(value);
    };

    const handleCantidadArticulo = ({ target }) => {
        const { value } = target;
        setCantidadArticulo(value);
    };

    const handlePrecioArticulo = ({ target }) => {
        const { value } = target;
        setPrecioArticulo(value);
    };

    const handleDescuentoArticulo = ({ target }) => {
        const { value } = target;
        setDescuentoArticulo(value);
    };

    const cancelButton = () => {
        setOpen(false);
        handleAction(false);
    };

    return (
        <>
            <button
                className="w-full block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                onClick={() => setOpen(!open)}
            >
                {actionText}
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto w-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-auto sm:p-6">
                                    <form onSubmit={handleChange}>
                                        {
                                            {
                                                del_article: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-red-600"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">
                                                                    ¿Está seguro
                                                                    que quiere
                                                                    eliminar el
                                                                    artículo "
                                                                    {
                                                                        data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ItemOperacion
                                                                            .Item
                                                                            .Nombre
                                                                    }
                                                                    " (
                                                                    {
                                                                        data
                                                                            .Items[
                                                                            indexItem
                                                                        ]
                                                                            .ItemOperacion
                                                                            .Item
                                                                            .Codigo
                                                                    }
                                                                    )
                                                                    <br />
                                                                    Esta acción
                                                                    no se puede
                                                                    deshacer.
                                                                    Deberá
                                                                    cargar
                                                                    nuevamente
                                                                    el artículo.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                                change_amount_article: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="">
                                                                <p className="text-sm text-gray-500">
                                                                    Debe
                                                                    escribir un
                                                                    numero
                                                                    válido.
                                                                </p>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    className="rounded-md text-xs"
                                                                    type="number"
                                                                    name="cantidad"
                                                                    value={
                                                                        cantidadArticulo
                                                                    }
                                                                    onChange={
                                                                        handleCantidadArticulo
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                                change_article_price: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="">
                                                                <p className="text-sm text-gray-500">
                                                                    Debe
                                                                    escribir un
                                                                    numero
                                                                    válido.
                                                                </p>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    className="rounded-md text-xs"
                                                                    type="number"
                                                                    name="cantidad"
                                                                    value={
                                                                        precioArticulo
                                                                    }
                                                                    onChange={
                                                                        handlePrecioArticulo
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                                change_article_discount_percentage:
                                                    (
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6 text-gray-500"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                                <Dialog.Title
                                                                    as="h3"
                                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                                >
                                                                    {actionText}
                                                                </Dialog.Title>
                                                                <div className="">
                                                                    <p className="text-sm text-gray-500">
                                                                        Debe
                                                                        escribir
                                                                        un
                                                                        numero
                                                                        válido.
                                                                    </p>
                                                                </div>
                                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                                        <span className="text-gray-500 sm:text-sm">
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        className="rounded-md text-xs pl-7 pr-12"
                                                                        type="text"
                                                                        name="cantidad"
                                                                        value={
                                                                            descuentoArticulo
                                                                        }
                                                                        onChange={
                                                                            handleDescuentoArticulo
                                                                        }
                                                                        autoComplete="off"
                                                                    />
                                                                </div>

                                                                {error && (
                                                                    <p className="text-xs text-red-500 font-semibold">
                                                                        {error}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ),
                                                change_article_delivery_date: (
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6 text-gray-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                {actionText}
                                                            </Dialog.Title>
                                                            <div className="">
                                                                <p className="text-sm text-gray-500">
                                                                    Debe
                                                                    escribir una
                                                                    fecha
                                                                    válida.
                                                                </p>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    className="rounded-md text-xs"
                                                                    type="date"
                                                                    value={
                                                                        fechaEntrega
                                                                    }
                                                                    onChange={
                                                                        handleFechaArticulo
                                                                    }
                                                                    name="fechaDelivery"
                                                                />
                                                            </div>
                                                            {error && (
                                                                <p className="text-xs text-red-500 font-semibold">
                                                                    {error}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                                change_article_delivery_deposit:
                                                    (
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                                <SelectorGenerico
                                                                    ruta={
                                                                        "deposito"
                                                                    }
                                                                    view={
                                                                        "single"
                                                                    }
                                                                    title={
                                                                        "Seleccione el déposito de entrega"
                                                                    }
                                                                />
                                                                {error && (
                                                                    <p className="text-xs text-red-500 font-semibold">
                                                                        {error}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ),
                                            }[action]
                                        }
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="submit"
                                                className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm ${
                                                    action === "del_article"
                                                        ? " bg-red-600 hover:bg-red-700"
                                                        : "bg-blue-600 hover:bg-blue-800"
                                                }`}
                                            >
                                                {action === "del_article"
                                                    ? "Eliminar"
                                                    : "Aceptar"}
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={cancelButton}
                                                ref={cancelButtonRef}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
