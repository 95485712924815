//react
import { useState, useEffect, useContext } from "react";
import Moment from "moment";
import axios from "axios";

//components

import { SelectorArticulo, SelectorContrato } from "../../../Selector";

//assets
import { Fragment } from "react";
import { Popover, Transition, Menu } from "@headlessui/react";
import UserContext from "../../../../context/UserContext";
import ModalContext from "../../../../context/ModalContext";
import AlertContext from "../../../../context/AlertContext";
import { ArticulosItem } from "./ArticulosItem";

export const SecondComponent = ({ nextStep, prevStep }) => {
    const { url, headers, user, workstationActive } = useContext(UserContext);
    const { data, setData } = useContext(ModalContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);

    var cantidad = 1;
    const [items, setItems] = useState([]);
    const objSelected = {};

    var tipoContrato = data.ContratoOperacion.AdmiteArticulosSinContrato;
    // console.log(data);

    const CategoriaSvg = () => {
        return (<svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
            />
        </svg>);
    }

    const EmpleadoSvg = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        );
    };

    const tabs = [
        { id: 1, title: "Categoria", svg: <CategoriaSvg /> },
        { id: 3, title: "Empleados", svg: <EmpleadoSvg /> },
    ];

    const handleAddArticle = async (e) => {
        // console.log(typeof e);
        if (typeof e === "object") {
            var idItem = e[0].obj.id;
            var fInicio = e[0].inicio;
            var fFin = e[0].fin;
        }else{
            var idItem = e;
        }
        // console.log(fInicio, fFin);
        if (tipoContrato) {
            await axios
                .post(
                    url +
                        `parteoperativo/add_article_period/${idItem}/${fInicio}/${fFin}`,
                    {
                        Workstation: workstationActive.WorkstationKey,
                        Usuario: user.UsuarioKey,
                        parteoperativo:data
                    }
                )
                .then(function (response) {
                    // console.log(response.data);
                    setData(response.data);
                    setItems(response.data.Items);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        } else {
            await axios
                .post(
                    url + `parteoperativo/add_article/${idItem}/${cantidad}`,
                    {
                        Workstation: workstationActive.WorkstationKey,
                        Usuario: user.UsuarioKey,
                        parteoperativo: data,
                    }
                )
                .then(function (response) {
                    setData(response.data);
                    setItems(response.data.Items);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        setItems(data.Items);
    }, [data]);

    const handleValidation = () => {
        nextStep();
        // var itemsHs = data.Items.map((m) => {
        //     return m.Horas;
        // });
        // var exceedHs = itemsHs.reduce((a, b) => a + b, 0);

        // // console.log(itemsHs);
        // if (tipoContrato) {
        //     if (itemsHs.length === 0) {
        //         setStatusCode(406);
        //         setResponseText("Debe seleccionar un item para avanzar.");
        //         setTimeout(() => {
        //             setResponseText("");
        //         }, 3000);
        //     } else {
        //         if (data.Valida24Hs) {
        //             if (exceedHs === 24) {
        //                 nextStep();
        //             } else {
        //                 setStatusCode(406);
        //                 setResponseText(
        //                     "El contrato tiene la condición de cumplir las 24hs. Revise sus items"
        //                 );
        //                 setTimeout(() => {
        //                     setResponseText("");
        //                 }, 3000);
        //             }
        //         } else {
        //             if (exceedHs > 24) {
        //                 setStatusCode(406);
        //                 setResponseText(
        //                     "Se excedió de horas para este parte operativo."
        //                 );
        //                 setTimeout(() => {
        //                     setResponseText("");
        //                 }, 3000);
        //             } else {
        //                 nextStep();
        //             }
        //         }
        //     }
        // } else {
        //     nextStep();
        // }
    };

    return (
        <>
            <div className="items-center mt-4">
                <div className="flex justify-between">
                    <button
                        className={`bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={prevStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.85Z"
                            ></path>
                        </svg>
                    </button>
                    <div className="mx-4 w-full flex items-center ">
                        {/* <div className="w-full max-w-md px-2 py-16 sm:px-0">
                    </div> */}

                        {tipoContrato ? (
                            <SelectorContrato
                                objSelected={objSelected}
                                onAddArticle={handleAddArticle}
                                contratoKey={data.ContratoOperacionKey}
                            />
                        ) : (
                            <SelectorArticulo
                                onAddArticle={handleAddArticle}
                                listaPrecioKey={
                                    data.Operacion.ListaPrecios.ListaPreciosKey
                                }
                            />
                        )}

                        <Popover className="relative">
                            <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    />
                                </svg>
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute z-10 mt-1.5 flex w-screen max-w-max">
                                    <div className="w-screen max-w-md flex-auto rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                        <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                            <div className="mt-1 flex flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="mt-1 text-gray-600 w-full flex justify-between">
                                                Adicionales
                                                <Menu>
                                                    <Menu.Button>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                                            />
                                                        </svg>
                                                    </Menu.Button>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute right-0 mt-10 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                            <div className="px-1 py-1 ">
                                                                <Menu.Item>
                                                                    {({
                                                                        close,
                                                                    }) => (
                                                                        <button>
                                                                            Cargar
                                                                            adicional
                                                                        </button>
                                                                        // <MyCustomLink
                                                                        //     href="/"
                                                                        //     onClick={close}
                                                                        // >
                                                                        //     Read and accept
                                                                        // </MyCustomLink>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>
                    </div>
                    <button
                        className={`ml-15 bg-indigo-600 text-white hover:bg-indigo-900 px-2 py-1 rounded-md shadow-md text-sm leading-7 font-semibold cursor-pointer`}
                        onClick={nextStep}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2em"
                            height="2em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="px-4 md:h-60 h-96 sm:px-6 md:mb-0 mb-5 lg:px-8 border border-transparent">
                <div className="flow-root">
                    <div className="-mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="overflow-auto max-h-60">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-300">
                                    <tr className="border">
                                        {tipoContrato && (
                                            <>
                                                <th
                                                    scope="col"
                                                    className="hidden md:sticky md:table-cell top-0 z-10 border-b text-left border-indigo-600 bg-gray-400/75 py-1 w-0 text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Inicio
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden md:sticky md:table-cell top-0 z-10 border-b text-left border-indigo-600 bg-gray-400/75 px-3 py-1 w-0 text-sm font-semibold text-gray-900"
                                                >
                                                    Fin
                                                </th>
                                            </>
                                        )}
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b text-left border-indigo-600 bg-gray-400/75 py-1 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            Cant
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-indigo-600 bg-gray-400/75 px-3 py-1 w-0 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <span className="md:block hidden">
                                                Descripción
                                            </span>
                                            <span className="md:hidden block py-1">
                                                Datos
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b text-left border-indigo-600 bg-gray-400/75 py-1 w-0 text-sm font-semibold text-gray-900"
                                        >
                                            Uni. NG
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b border-indigo-600 bg-gray-400/75 py-1 w-0 text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            Total NG
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden md:sticky md:table-cell top-0 z-10 border-b border-indigo-600 bg-gray-400/75 py-1 px-3 w-0 text-sm font-semibold text-gray-900 sm:pr-3"
                                        >
                                            Maniobra
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-indigo-600 bg-gray-400/75 w-0 py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white ">
                                    {items.map((item, itemIdx) => (
                                        <ArticulosItem
                                            key={itemIdx}
                                            item={item}
                                            itemIdx={itemIdx}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
