import { useContext } from "react";
import UserContext from "../context/UserContext";

const globalActions = {
    //UTILS
    FORMAT_TABLE: 870,
    FILTER_TABLE: 329,

    USUARIO_SWITCH_WORKSTATION: 8001,

    //SIDEBAR LINKS
    MAIN_MENU_INICIO: 8219,
    MAIN_MENU_LISTADO: 8220,
    MAIN_MENU_LISTADO_ARTICULO: 8221,
    MAIN_MENU_LISTADO_BANCO: 8222,
    MAIN_MENU_LISTADO_CENTROCOSTO: 8223,
    MAIN_MENU_LISTADO_CLIENTE: 8224,
    MAIN_MENU_LISTADO_CONDICIONPAGO: 8225,
    MAIN_MENU_LISTADO_MARCA: 8226,
    MAIN_MENU_LISTADO_MONEDA: 8227,
    MAIN_MENU_LISTADO_PROVEEDOR: 8228,
    MAIN_MENU_LISTADO_SECTOR: 8229,
    MAIN_MENU_LISTADO_VENDEDOR: 8230,
    MAIN_MENU_LISTADO_UNIDAD: 8231,
    MAIN_MENU_LISTADO_ZONA: 8232,
    MAIN_MENU_OPERACION_ORDEN_PEDIDO_DEPOSITO: 8233,
    MAIN_MENU_OPERACION_PRESUPUESTO_CLIENTE: 8002,
    MAIN_MENU_OPERACION_ORDEN_COMPRA_CLIENTE: 8003,
    MAIN_MENU_OPERACION_PARTE_OPERATIVO: 8004,
    MAIN_MENU_OPERACION_ORDEN_TRABAJO: 8005,
    MAIN_MENU_OPERACION_FACTURAS_CLIENTE: 8006,
    MAIN_MENU_OPERACION_ORDEN_PEDIDO_SECTORIZADA: 8007,
    MAIN_MENU_OPERACION_PRESUPUESTO_PROVEEDOR: 8008,
    MAIN_MENU_OPERACION_ORDEN_COMPRA_PROVEEDOR: 8009,
    MAIN_MENU_OPERACION_ORDEN_TRABAJO_INTERNA: 8010,
    MAIN_MENU_OPERACION_VENTAS: 8219,

    //OPERACION_VENTA
    OPERACION_VENTA_NUEVO: 82,
    OPERACION_VENTA_CONDICION_PAGO: 23,
    OPERACION_VENTA_CLIENTE: 24,
    OPERACION_VENTA_TIPOFACTURA: 25,
    OPERACION_VENTA_DESCUENTO: 26,
    OPERACION_VENTA_LISTAPRECIOS: 27,
    OPERACION_VENTA_GUARDAR: 28,
    OPERACION_VENTA_IMPRIMIRPRESUPUESTO: 29,
    OPERACION_VENTA_CLIENTETMP: 30,
    OPERACION_VENTA_BORRARARTICULO: 31,
    OPERACION_VENTA_CAMBIARPRECIOARTICULO: 32,
    OPERACION_VENTA_CAMBIARCANTIDADARTICULO: 33,
    OPERACION_VENTA_DESCUENTOARTICULO: 34,

    //OPERACION_PRESUPUESTO_CLIENTE_CLIENTE
    OPERACION_PRESUPUESTO_CLIENTE_NUEVA: 8011,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR: 8012,
    OPERACION_PRESUPUESTO_CLIENTE_ELIMINAR: 8013,
    OPERACION_PRESUPUESTO_CLIENTE_ADJUNTOS_BROWSER: 8014,
    OPERACION_PRESUPUESTO_CLIENTE_CADENA_BROWSER: 8015,
    OPERACION_PRESUPUESTO_CLIENTE_EVENTOS: 8016,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_VENDEDOR: 8017,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_LOCAL: 8018,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_SECTOR: 8019,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_ARTICULO: 8020,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_CENTROCOSTO: 8021,
    OPERACION_PRESUPUESTO_CLIENTE_FILTRO_CLIENTE: 8022,
    OPERACION_PRESUPUESTO_CLIENTE_CAMBIAR_ESTADO_VISADO: 8023,
    OPERACION_PRESUPUESTO_CLIENTE_CAMBIAR_ESTADO_APROBADO: 8024,
    OPERACION_PRESUPUESTO_CLIENTE_CAMBIAR_ESTADO_DESAPROBADO: 8025,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_CONDICIONPAGO: 8026,
    OPERACION_PRESUPUESTO_CLIENTE_AGREGAR_ARTICULOS: 8027,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_LISTAPRECIOS: 8028,
    OPERACION_PRESUPUESTO_CLIENTE_SELECCION_VENDEDOR: 8029,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_FECHA_EMISION: 8030,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_FECHA_VENCIMIENTO: 8031,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_MONEDA: 8032,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_CENTROCOSTO: 8033,
    OPERACION_PRESUPUESTO_CLIENTE_ESTABLECER_DESCUENTO_GENERAL: 8034,
    OPERACION_PRESUPUESTO_CLIENTE_ESTABLECER_DESCUENTO_ADMINISTRADOR: 8035,
    OPERACION_PRESUPUESTO_CLIENTE_ESTABLECER_CENTROCOSTO: 8036,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_PRECIO_ITEM: 8037,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_DESCRIPCION_ITEM: 8038,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_DESCUENTO_ITEM: 8039,
    OPERACION_PRESUPUESTO_CLIENTE_MODIFICAR_DIAS_ENTREGA: 8040,

    //OPERACION_ORDENTRABAJOINTERNA
    OPERACION_ORDEN_TRABAJO_INTERNA_MODIFICAR: 146,
    OPERACION_ORDEN_TRABAJO_INTERNA_ELIMINAR: 147,
    OPERACION_ORDEN_TRABAJO_INTERNA_NUEVA: 151,

    //OPERACION_ORDEN_PEDIDO
    OPERACION_ORDEN_PEDIDO_EVALUAR: 170,
    OPERACION_ORDEN_PEDIDO_APROBAR: 171,
    OPERACION_ORDEN_PEDIDO_DESAPROBAR: 172,
    OPERACION_ORDEN_PEDIDO_DESHACERVENCIDA: 173,
    OPERACION_ORDEN_PEDIDO_GENERARPRESUPUESTO: 177,
    OPERACION_ORDEN_PEDIDO_ESTADO_APROBADA: 8102,
    OPERACION_ORDEN_PEDIDO_ESTADO_DESAPROBADA: 8103,
    OPERACION_ORDEN_PEDIDO_ESTADO_VENCIDA: 8104,
    OPERACION_ORDEN_PEDIDO_ESTADOITEM_APROBAR: 8105,
    OPERACION_ORDEN_PEDIDO_ESTADOITEM_DESAPROBAR: 8106,
    OPERACION_ORDEN_PEDIDO_ITEM_TIPOAPROBACION: 8107,
    OPERACION_ORDEN_PEDIDO_GENERARPRESUPUESTO_RUBRO: 8108,
    OPERACION_ORDEN_PEDIDO_MODIFICAR: 8109,
    OPERACION_ORDEN_PEDIDO_ELIMINAR: 8110,
    OPERACION_ORDEN_PEDIDO_GENERAR_RS: 8111,
    OPERACION_ORDEN_PEDIDO_GENERAR_RE: 8112,
    OPERACION_ORDEN_PEDIDO_ESTADOITEM_APROBARTODOS: 8113,
    OPERACION_ORDEN_PEDIDO_ESTADOITEM_DESAPROBARTODOS: 8114,
    OPERACION_ORDEN_PEDIDO_GENERAR_OCP: 8115,
    OPERACION_ORDEN_PEDIDO_CADENA_BROWSER: 8116,
    OPERACION_ORDEN_PEDIDO_CADENAITEMS_BROWSER: 8117,
    OPERACION_ORDEN_PEDIDO_LOG: 8118,
    OPERACION_ORDEN_PEDIDO_GENERARPRESUPUESTO_PROVEEDOR: 8119,
    OPERACION_ORDEN_PEDIDO_ADJUNTOS_BROWSER: 8120,
    OPERACION_ORDEN_PEDIDO_COMPARATIVOPRESUPUESTOS: 8121,
    OPERACION_ORDEN_PEDIDO_VINCULAR_RE: 8122,
    OPERACION_ORDEN_PEDIDO_FILTRO_SECTORESTODOS: 8123,
    OPERACION_ORDEN_PEDIDO_CLONAR: 8124,
    OPERACION_ORDEN_PEDIDO_FILTRO_DEPOSITO: 8125,
    OPERACION_ORDEN_PEDIDO_DESCARTARCONTROLCUMPLIMIENTO: 8126,
    OPERACION_ORDEN_PEDIDO_SECTORIZADA_NUEVA: 8127,
    OPERACION_ORDEN_PEDIDO_DEPOSITO_NUEVA: 8128,
    OPERACION_ORDEN_PEDIDO_ESTADO_EVALUARYAPROBAR: 8129,
    OPERACION_ORDEN_PEDIDO_MODIFICAR_EVALUACION: 8130,
    OPERACION_ORDEN_PEDIDO_MODIFICAR_APROBADA: 8131,
    OPERACION_ORDEN_PEDIDO_ESTADO_DESHACER: 8132,
    OPERACION_ORDEN_PEDIDO_ESTADO_DESHACERFORZADO: 8133,
    OPERACION_ORDEN_PEDIDO_GENERA_OTI: 8134,
    OPERACION_ORDEN_PEDIDO_BLOQUEARCIRCUITOCOMPRA: 8135,
    OPERACION_ORDEN_PEDIDO_DESBLOQUEARCIRCUITOCOMPRA: 8136,
    OPERACION_ORDEN_PEDIDO_ASIGNARCOMPRADOR: 8137,
    OPERACION_ORDEN_PEDIDO_ITEM_REEMPLAZARGENERICO: 8138,
    OPERACION_ORDEN_PEDIDO_ITEM_ASIGNARBIENUSO: 8139,
    OPERACION_ORDEN_PEDIDO_ITEM_ASIGNARLOTEDESTINO: 8140,
    OPERACION_ORDEN_PEDIDO_ITEM_QUITARBIENUSO: 8141,
    OPERACION_ORDEN_PEDIDO_ITEM_QUITARLOTEDESTINO: 8142,
    OPERACION_ORDEN_PEDIDO_GENERICO: 8143,
    OPERACION_ORDEN_PEDIDO_DEPOSITODESTINO: 8144,
    OPERACION_ORDEN_PEDIDO_ITEM_EDITARDESCRIPCION: 8145,
    OPERACION_ORDEN_PEDIDO_MONEDA: 8146,
    OPERACION_ORDEN_PEDIDO_FECHAEMISION: 8147,
    OPERACION_ORDEN_PEDIDO_DEPOSITOENVIA: 8148,
    OPERACION_ORDEN_PEDIDO_ITEM_UNIDAD: 8149,
    OPERACION_ORDEN_PEDIDO_ESTADO_EVALUACION: 8150,

    //
    OPERACION_ITEM_ORDEN_PEDIDO_APROBAR: 174,
    OPERACION_ITEM_ORDEN_PEDIDO_DESAPROBAR: 175,
    OPERACION_ITEM_ORDEN_PEDIDO_CAMBIARTIPOAPROBACION: 176,

    //OPERACION_ORDEN_PEDIDO_INTERNA

    //OPERACION_PARTE_OPERATIVO
    OPERACION_PARTE_OPERATIVO_MODIFICAR_CENTROCOSTO: 1617,
    OPERACION_PARTE_OPERATIVO_NUEVA: 8151,
    OPERACION_PARTE_OPERATIVO_MODIFICAR: 8152,
    OPERACION_PARTE_OPERATIVO_ELIMINAR: 8153,
    OPERACION_PARTE_OPERATIVO_ADJUNTOS: 8154,
    OPERACION_PARTE_OPERATIVO_CADENA: 8155,
    OPERACION_PARTE_OPERATIVO_FILTROS_SECTOR: 8156,
    OPERACION_PARTE_OPERATIVO_FILTROS_UNIDADNEGOCIO: 8157,
    OPERACION_PARTE_OPERATIVO_FILTROS_CLIENTE: 8158,
    OPERACION_PARTE_OPERATIVO_FILTROS_CONTACTO: 8159,
    OPERACION_PARTE_OPERATIVO_FILTROS_ZONA: 8160,
    OPERACION_PARTE_OPERATIVO_FILTROS_ARTICULO: 8161,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_MONEDA: 8162,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_OPERACION: 8163,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_SECTOR: 8164,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_NEGOCIO: 8165,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_CLIENTE: 8166,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_ZONA: 8167,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_NUMERO: 8168,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_FECHA: 8169,
    OPERACION_PARTE_OPERATIVO_MODIFICAR_VENCIMIENTO: 8170,

    //OPERACION_ORDEN_COMPRA_CLIENTE
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM_PRECIO: 795,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM: 796,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM_DESCUENTO: 861,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_ITEM_DEPOSITO_ENTREGA: 1077,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_FECHA: 1341,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_LUGAR_ENTREGA: 295,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_SECTOR_CLIENTE: 1405,
    OPERACION_ORDEN_COMPRA_CLIENTE_IMPRIMIR_PDF: 1004,
    OPERACION_ORDEN_COMPRA_CLIENTE_NUEVA: 8041,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR: 8042,
    OPERACION_ORDEN_COMPRA_CLIENTE_ELIMINAR: 8043,
    OPERACION_ORDEN_COMPRA_CLIENTE_ADJUNTOS_BROWSER: 8044,
    OPERACION_ORDEN_COMPRA_CLIENTE_CADENA_BROWSER: 8045,
    OPERACION_ORDEN_COMPRA_CLIENTE_EVENTOS: 8046,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_REMITO_CUMPLIMIENTO: 8047,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_REMITO_DEVOLUCION: 8048,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_FACTURA: 8049,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_DESCARTAR_CONTROLCUMPLIMIENTO: 8050,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_OPI_DEPOSITOS: 8051,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_OT: 8052,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_VINCULAR_REMITO_ENTRADA: 8053,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_VINCULAR_REMITO_SALIDA: 8054,
    OPERACION_ORDEN_COMPRA_CLIENTE_CADENA_ITEMS: 8055,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_OCP_PROVEEDOR_DEFAULT: 8056,
    OPERACION_ORDEN_COMPRA_CLIENTE_LOG: 8057,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_PICKING_REMTO_SUCURSAL: 8058,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_VINCULAR_FACTURA: 8059,
    OPERACION_ORDEN_COMPRA_CLIENTE_IMPRIMIR_EMITIDA: 8060,
    OPERACION_ORDEN_COMPRA_CLIENTE_IMPRIMIR_EN_PROCESO: 8061,
    OPERACION_ORDEN_COMPRA_CLIENTE_REENVIAR: 8062,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_APROBAR: 8063,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_DESAPROBAR: 8064,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_DESHACER_VENCIDO: 8065,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_OCP_PROVEEDOR_ELECCION: 8066,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_FACTURAR_UNIDADNEGOCIO: 8067,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_DESHACER: 8068,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_DESHACER_FORZADO: 8069,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_DESHACER_CONTROL_CUMPLIMIENTO: 8070,
    OPERACION_ORDEN_COMPRA_CLIENTE_ASIGNAR_CONSOLIDADO_REPARTO: 8071,
    OPERACION_ORDEN_COMPRA_CLIENTE_IMPRESION_CONSOLIDADO_REPARTO: 8072,
    OPERACION_ORDEN_COMPRA_CLIENTE_VER_CONSOLIDADO_REPARTO: 8073,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_RS_PROVEEDOR: 8074,
    OPERACION_ORDEN_COMPRA_CLIENTE_SET_TIPO_OPERACION_STOCK: 8075,
    OPERACION_ORDEN_COMPRA_CLIENTE_ESTADO_OBSERVADA: 8076,
    OPERACION_ORDEN_COMPRA_CLIENTE_REEMPLAZAR_ITEM_EQUIVALENTE: 8077,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_FACTURAR_MULTIPLE: 8078,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_FACTURAR_MULTIPLE_CLIENTE: 8079,
    OPERACION_ORDEN_COMPRA_CLIENTE_QUITAR_CONSOLIDADO_REPARTO: 8080,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_APROBADA: 8081,
    OPERACION_ORDEN_COMPRA_CLIENTE_CAMBIAR_DEPOSITO_ENTREGA: 8082,
    OPERACION_ORDEN_COMPRA_CLIENTE_VINULAR_FACTURA_ITEMS_DIFERENTES: 8083,
    OPERACION_ORDEN_COMPRA_CLIENTE_ADMIN: 8084,
    OPERACION_ORDEN_COMPRA_CLIENTE_GESTION_GENERAR_OPI_SECTORIZADA: 8085,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_CONDICION_PAGO: 8086,
    OPERACION_ORDEN_COMPRA_CLIENTE_EDITAR_PRECIO: 8087,
    OPERACION_ORDEN_COMPRA_CLIENTE_AGREGAR_ARTICULO_GENERICO: 8088,
    OPERACION_ORDEN_COMPRA_CLIENTE_ITEM_EDITAR_DESCRIPCION: 8089,
    OPERACION_ORDEN_COMPRA_CLIENTE_LUGAR_ENTREGA: 8090,
    OPERACION_ORDEN_COMPRA_CLIENTE_ITEM_DESCUENTO: 8091,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_DESCUENTO_GENERAL: 8092,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_MONEDA: 8093,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_LISTA_PRECIOS: 8094,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_SECTOR: 8095,
    OPERACION_ORDEN_COMPRA_CLIENTE_FACTURARVENCIDO: 8096,
    OPERACION_ORDEN_COMPRA_CLIENTE_ITEM_DEPOSITOENTREGA: 8097,
    OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_DEPOSITO_ENTREGA: 8098,
    OPERACION_ORDEN_COMPRA_CLIENTE_APROBARAUTOMATICAMENTE: 8099,
    OPERACION_ORDEN_COMPRA_CLIENTE_FECHAEMISION: 8100,
    OPERACION_ORDEN_COMPRA_CLIENTE_SECTORCLIENTE: 8101,
    OPERACION_ORDEN_COMPRA_CLIENTE_EVENTOS: 8176,

    //OPERACION_ORDEN_COMPRA_PROVEEDOR
    OPI_GENERAR_OPERACION_ORDEN_COMPRA_PROVEEDOR: 8115,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_NUEVA: 8171,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_MODIFICAR: 8172,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ELIMINAR: 8173,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ADJUNTOS_BROWSER: 8174,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CADENA_BROWSER: 8175,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_LOG: 8177,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CALIFICAR: 8178,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CADENAITEMS_BROWSER: 8179,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_MODIFICARAPROBADA: 8180,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_IMPRIMIREMITIDA: 8181,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_MODIFICARENCABEZADO: 8182,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_IMPRIMIRPROCESADOS: 8183,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CAMBIARDEPOSITOENTREGA: 8184,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_REENVIAR: 8185,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_FACTURAR: 8186,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_REMITAR: 8187,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_VINCULARFACTURA: 8188,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_VINCULARREMITO: 8189,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DESBLOQUEARAPROBADA: 8190,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DESCARTARCONTROLCUMPLIMIENTO: 8191,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_REMITARDEVOLUCION: 8192,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CLONAR: 8193,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_VIEWBYSECTOR: 8194,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_REMITIRITEMSGENERICOS: 8195,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_REEMPLAZARITEMGENERICO: 8196,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_FORZARDESHACERESTADO: 8197,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_UNDO_DESCARTARCONTROLCUMPLIMIENTO: 8198,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_VISAR: 8199,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CERTIFICAR: 8200,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_APROBAR: 8201,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DESAPROBAR: 8202,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DESHACERVENCIDA: 8203,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_GENERICO: 8204,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_CC: 8205,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_EDITARDESCRIPCION: 8206,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DESCUENTOGENERAL: 8207,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_DEPOSITOENTREGA: 8208,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_MONEDA: 8209,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_CC_ALL: 8210,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_CUENTABANCARIAPROVEEDOR: 8211,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_EMPLEADOSOLICITA: 8212,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_DESCUENTO: 8213,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_SECTOR: 8214,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_EDITARPRECIO: 8215,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_LOTEDESTINO: 8216,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ITEM_BIENUSO: 8217,
    OPERACION_ORDEN_COMPRA_PROVEEDOR_ABIERTA: 8218,

    /*
    OCP_NUEVA: 8171,
    OCP_MODIFICAR: 8172,
    OCP_ELIMINAR: 8173,
    OCP_ADJUNTOS_BROWSER: 8174,
    OCP_CADENA_BROWSER: 8175,
    OCP_LOG: 8177,
    OCP_CALIFICAR: 8178,
    OCP_CADENAITEMS_BROWSER: 8179,
    OCP_MODIFICARAPROBADA: 8180,
    OCP_IMPRIMIREMITIDA: 8181,
    OCP_MODIFICARENCABEZADO: 8182,
    OCP_IMPRIMIRPROCESADOS: 8183,
    OCP_CAMBIARDEPOSITOENTREGA: 8184,
    OCP_REENVIAR: 8185,
    OCP_FACTURAR: 8186,
    OCP_REMITAR: 8187,
    OCP_VINCULARFACTURA: 8188,
    OCP_VINCULARREMITO: 8189,
    OCP_DESBLOQUEARAPROBADA: 8190,
    OCP_DESCARTARCONTROLCUMPLIMIENTO: 8191,
    OCP_REMITARDEVOLUCION: 8192,
    OCP_CLONAR: 8193,
    OCP_VIEWBYSECTOR: 8194,
    OCP_REMITIRITEMSGENERICOS: 8195,
    OCP_REEMPLAZARITEMGENERICO: 8196,
    OCP_FORZARDESHACERESTADO: 8197,
    OCP_UNDO_DESCARTARCONTROLCUMPLIMIENTO: 8198,
    OCP_VISAR: 8199,
    OCP_CERTIFICAR: 8200,
    OCP_APROBAR: 8201,
    OCP_DESAPROBAR: 8202,
    OCP_DESHACERVENCIDA: 8203,
    OCP_GENERICO: 8204,
    OCP_ITEM_CC: 8205,
    OCP_ITEM_EDITARDESCRIPCION: 8206,
    OCP_DESCUENTOGENERAL: 8207,
    OCP_DEPOSITOENTREGA: 8208,
    OCP_MONEDA: 8209,
    OCP_ITEM_CC_ALL: 8210,
    OCP_CUENTABANCARIAPROVEEDOR: 8211,
    OCP_EMPLEADOSOLICITA: 8212,
    OCP_ITEM_DESCUENTO: 8213,
    OCP_SECTOR: 8214,
    OCP_EDITARPRECIO: 8215,
    OCP_ITEM_LOTEDESTINO: 8216,
    OCP_ITEM_BIENUSO: 8217,
    OCP_ABIERTA: 8218,
*/

    //OPERACION_ORDEN_COMPRA_CLIENTE_MODIFICAR_TIPOENTREGA: 228,
    // OPERACION_ORDEN_COMPRA_CLIENTE_APROBAR: 178,
    // OPERACION_ORDEN_COMPRA_CLIENTE_DESAPROBAR: 179,
    // OPERACION_ORDEN_COMPRA_CLIENTE_DESHACERESTADOVENCIDA: 180,
    // OPERACION_ORDEN_COMPRA_CLIENTE_IMPRIMIR: 185,
    // OPERACION_ORDEN_COMPRA_CLIENTE_REMITOCANCELACION: 239,
    // OPERACION_ORDEN_COMPRA_CLIENTE_REMITODEVOLUCION: 240,
    // OPERACION_ORDEN_COMPRA_CLIENTE_APROBAR: 915,
    // OPERACION_ORDEN_COMPRA_CLIENTE_DESAPROBAR: 916,
};

const operationBrowserActions = {
    BROWSER_OPERACION_ORDEN_PEDIDO: 167,
    BROWSER_OPERACION_ORDEN_TRABAJO: 142,
    BROWSER_OPERACION_ORDENCOMPRACLIENTE: 479,
    BROWSER_OPERACION_PARTEOPERATIVO_HORA: 1597,
    BROWSER_OPERACION_PARTEOPERATIVO_CANTIDAD: 1109,
    BROWSER_OPERACION_VENTA: 71,
    BROWSER_OPERACION_PRESUPUESTO_CLIENTE: 9999,
};

const selectorBrowserActions = {
    BROWSER_ARTICULO: 52,
    BROWSER_BANCO: 111,
    BROWSER_CENTROCOSTO: 169,
    BROWSER_CLIENTE: 97,
    BROWSER_CONTRATOOPERACION: 1101,
    BROWSER_DEPOSITO: 332,
    BROWSER_FORMAPAGO: 35,
    BROWSER_LISTAPRECIO: 54,
    BROWSER_LOCALIDAD: 101,
    BROWSER_MARCA: 56,
    BROWSER_MONEDA: 3,
    BROWSER_PAIS: 101,
    BROWSER_PROVEEDOR: 98,
    BROWSER_PROVINCIA: 101,
    BROWSER_SECTOR: 368,
    BROWSER_SITUACIONIVA: 1230,
    BROWSER_UNIDAD: 53,
    BROWSER_VENDEDOR: 17,
    BROWSER_WORKSTATION: 1045,
};

export const useActions = (accionesUser = []) => {
    const parseRoute = ($route) => {
        switch ($route) {
            case "ordencompracliente":
                return "OPERACION_ORDEN_COMPRA_CLIENTE_";
            case "ordencompraproveedor":
                return "OPERACION_ORDEN_COMPRA_PROVEEDOR_";
            case "parteoperativo":
                return "OPERACION_PARTE_OPERATIVO_";
            case "venta":
                return "OPERACION_VENTA_";
            case "presupuestocliente":
                return "OPERACION_PRESUPUESTO_CLIENTE_";
            case "ordenpedidointerna":
            case "ordenpedidointernadeposito":
            case "ordenpedidointernasectorizada":
                return "OPERACION_ORDEN_PEDIDO_";
            case "ordentrabajointerna":
                return "OPERACION_ORDEN_TRABAJO_INTERNA_";
            default:
                break;
        }
    };

    const isAllowed = (action) => {
        var allowed = false;        

        Object.keys(globalActions).forEach((key) => {
            if (key === action) {
                accionesUser.find((au) => {
                    if (au === globalActions[key]) {
                        allowed = true;
                    } else {
                        return allowed;
                    }
                });
            }
        });
        return allowed;
    };

    const isAllowedMany = (arrayActions = []) => {
        var allowed = false;

        arrayActions.map((aa) => {
            Object.values(globalActions).find((value) => {
                if(aa === value){
                    allowed = true;
                }
            })
        })
        return allowed;
            
    };

    return { isAllowed, isAllowedMany, parseRoute };
};
